jQuery("document").ready(function ($) {

  /* Disable due to using superfish menu
  if($( window ).width() > 991){
    $('.topmenu ul.nav li.dropdown').hover(function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(200);
    }, function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(200);
    });

    $('.topmenu ul.nav .dropdown-toggle').each(function() {
      $(this).removeAttr('data-target');
      $(this).removeAttr('data-toggle');
      $(this).removeAttr('class');
    });
  }

  $('li.dropdown').on('click touchstart', function() {
    var $el = $(this);
    if ($el.hasClass('open')) {
      var $a = $el.children('a.dropdown-toggle');
      if ($a.length && $a.attr('href')) {
        location.href = $a.attr('href');
      }
    }
  });
  */

  $('.header').removeClass('open');
  $('.overlay').removeClass('shadow');
  $('.mobile_header .menu-btn').click(function(e){
    var e=window.event||e;
    $('.header').addClass('open');
    $('.overlay').addClass('shadow');
    e.stopPropagation();
  });

  $('.header .close').click(function(e){
    $('.header').removeClass('open');
    $('.overlay').removeClass('shadow');
  });

  // Set the width of the Goal Progress
  $('.progress-inner').each(function(){

    if( $(this).data('completed') == 1 ){
      $(this).css('width', '100%');
      $(this).text('100%');
    } else if( $(this).data('width') > 0 ) {
      $(this).css('width', $(this).data('width') + '%');
    } else{
      $(this).css('width', '0');
    }
  });

  // Goal Complete filter
  $('#complete-filter').change(function(){
    var checked = this.checked ? 'Yes' : 'No';

    if(checked == 'Yes'){
      $('.goal-completed-filter-1').fadeIn();
      $('.goal-expired-yes').fadeIn();

    } else{
      $('.goal-completed-filter-1').fadeOut();
      $('.goal-expired-yes').fadeOut();
    }
  });

  // Disable footstep checkboxes for completed and expired tasks
  $('.goal-completion-status-1 .text-checkboxes').prop('disabled', true);
  $('.goal-expiration-status-1 .text-checkboxes').prop('disabled', true);

  // Reload My-Wero page when opened by browser back button
  $( window ).unload(function() {
    $('#complete-filter').attr('checked', false);
  });


  // Show Popup Modal Message before deleting Wero
  $('.show-confirmation-modal').click(function(e){
    //e.preventDefault();
    var mymodal = $('#confirmationModal');
    mymodal.find('.modal-title').text($(this).data('header'));
    mymodal.find('.modal-body').text($(this).data('body'));
    if($(this).attr("href")) {
      mymodal.find('.modal-footer .modal-action-button').attr("href", $(this).attr("href"));
    }
    if($(this).data('actionbuttonlabel')) {
      mymodal.find('.modal-footer .modal-action-button').text($(this).data('actionbuttonlabel'));
    }
    mymodal.modal('toggle');

    return false;
  });


  // Handle Add Friend Form Search Button
  $('#wf-search-form-submit').click(function(e){
    var url = "/wf-search-and-add-friend";
    var email = $('#wf-search-form #email_address').val();
    var mymodal = $('#confirmationModal');

    // Check for valid Email Address
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if( !regex.test(email) ){
      mymodal.find('.modal-title').text('OL@-OR@ - Warning');
      mymodal.find('.modal-body').text('Please enter a valid Email Address.');
      mymodal.find('.modal-footer .modal-action-button').addClass('hidden');
      mymodal.modal('toggle');
    } else {

      $.ajax({

        url: url,
        type: 'post',
        data: {'email': email},
        success: function (data, status) {
          //console.log(data);

          if( data['status'] == 'found' ){

            // For Pasifika
            if ($(".user-role-pasifika").length > 0) {
              mymodal.find('.modal-title').text('Send Friend Invitation?');
              mymodal.find('.modal-body').text('Friend Invitation to ' + data['name']);
            } else{
              mymodal.find('.modal-title').text('Send Whānau Invitation?');
              mymodal.find('.modal-body').text('Whānau Invitation to ' + data['name']);
            }
            mymodal.find('.modal-footer .modal-action-button').removeClass('hidden');
            mymodal.find('.modal-footer .modal-action-button').attr("href", data['url']);
            mymodal.modal('toggle');

            $('#wf-search-form #email_address').val('');

          } else if ( data['status'] == 'not-found' ){
            $('#wf-search-form-box').addClass('hidden');
            $('.wf-send-email-invition-box').removeClass('hidden');
            $(".wf-send-email-invition-box #edit-submitted-email").prop("readonly", true);
          } else if ( data['status'] == 'warning' ){
            mymodal.find('.modal-title').text('OL@-OR@ - Warning');
            mymodal.find('.modal-body').text(data['message']);
            mymodal.find('.modal-footer .modal-action-button').addClass('hidden');
            mymodal.modal('toggle');
          }
        },
        error: function (xhr, desc, err) {
          $('.task-checkboxes').attr('disabled', false);
          console.log(xhr);
          console.log("Details: " + desc + "\nError:" + err);
        }

      });
    }

    e.preventDefault();

  });

  // Handle Send Friend Invitation from Cluster Page
  $('.send-cluster-friend-invitation').click(function(e){
    var url = "/wf-send-cluster-friend-invitation";
    var mymodal = $('#confirmationModal');
    var friend_id = $(this).data('friendid');
    var currentlink = $('#friend-thumbnail-container-' + friend_id);

    $.ajax({

      url: url,
      type: 'post',
      data: {'friend_id': friend_id},
      success: function (data, status) {
        //console.log(data);

        if( data['status'] == 'sent' ){

          mymodal.find('.modal-title').text('OL@-OR@ - Friend Invitation');
          if ($(".user-role-pasifika").length > 0) {
            mymodal.find('.modal-body').text('Friend Invitation sent successfully to ' + data['name']);
          } else{
            mymodal.find('.modal-body').text('Whānau Invitation sent successfully to ' + data['name']);
          }
          mymodal.find('.modal-footer .modal-action-button').addClass('hidden');
          currentlink.find('a').prop('disabled', true);
          mymodal.modal('toggle');

        } else if ( data['status'] == 'warning' ){
          mymodal.find('.modal-title').text('OL@-OR@ - Warning');
          mymodal.find('.modal-body').text(data['message']);
          mymodal.find('.modal-footer .modal-action-button').addClass('hidden');
          mymodal.modal('toggle');
        }
      },
      error: function (xhr, desc, err) {
        $('.task-checkboxes').attr('disabled', false);
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

    e.preventDefault();

  });

  // Automatically fill in Email Input in Send Email Invitation Form
  $("#wf-search-form #email_address").keyup(function(){
    $(".wf-send-email-invition-box #edit-submitted-email").val($(this).val());
  });

  // Focus on Email Input after closing Modal
  $('#confirmationModal').on('hide.bs.modal', function (e) {
    $('#wf-search-form #email_address').focus();
    if( $('#wf-search-form #email_address').length ){
      $('#wf-search-form #email_address').focus();
    }
  });


  // Set the modal title using Goal title
  $('#goal-title-congrats').text($('.goal-information .goal-title').text());


  // Set the progress bar depending on the form shown in the registration page
  if( $('#block-webform-client-block-910637 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '0%');
    $('.reg-progress-inner').text('0%');
  } else if( $('#block-webform-client-block-909995 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '17%');
    $('.reg-progress-inner').text('17%');
  } else if( $('#block-webform-client-block-910639 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '33%');
    $('.reg-progress-inner').text('33%');
  } else if( $('#block-webform-client-block-910640 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '50%');
    $('.reg-progress-inner').text('50%');
  } else if( $('#block-webform-client-block-910641 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '67%');
    $('.reg-progress-inner').text('67%');
  } else if( $('#block-webform-client-block-910642 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '67%');
    $('.reg-progress-inner').text('67%');
  } else if( $('#block-webform-client-block-911198 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '83%');
    $('.reg-progress-inner').text('83%');
  }


  // Under 18 Progress Bar
  // Set the progress bar depending on the form shown in the registration page
  if( $('#block-webform-client-block-910632 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '0%');
    $('.reg-progress-inner').text('0%');
  } else if( $('#block-webform-client-block-911105 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '33%');
    $('.reg-progress-inner').text('33%');
  } else if( $('#block-webform-client-block-909996 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '66%');
    $('.reg-progress-inner').text('66%');
  }


  // Invited Participant Progress Bar
  if( $('.page-node-910960 #block-webform-client-block-910638 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '0%');
    $('.reg-progress-inner').text('0%');
  } else if( $('.page-node-910960 #block-webform-client-block-910637 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '10%');
    $('.reg-progress-inner').text('10%');
  } else if( $('.page-node-910960 #block-webform-client-block-909995 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '25%');
    $('.reg-progress-inner').text('25%');
  } else if( $('.page-node-910960 #block-webform-client-block-910639 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '40%');
    $('.reg-progress-inner').text('40%');
  } else if( $('.page-node-910960 #block-webform-client-block-910640 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '55%');
    $('.reg-progress-inner').text('55%');
  } else if( $('.page-node-910960 #block-webform-client-block-910641 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '70%');
    $('.reg-progress-inner').text('70%');
  } else if( $('.page-node-910960 #block-webform-client-block-910642 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '70%');
    $('.reg-progress-inner').text('70%');
  } else if( $('.page-node-910960 #block-webform-client-block-911198 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '85%');
    $('.reg-progress-inner').text('85%');
  }

  // Invited Participant non-trial consent
  if( $('.page-node-910960 #block-webform-client-block-913735 form').length ){
    $('.reg-progress-outer').css('display', 'block');
    $('.reg-progress-inner').css('width', '33%');
    $('.reg-progress-inner').text('33%');
  }

  // Allow only number as input
  $('.number-only').keydown(function(e) {
    /*
    if(key.charCode < 48 || key.charCode > 57) {
      return false;
    }
    */

    var key = e.charCode || e.keyCode || 0;
    // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
    // home, end, period, and numpad decimal
    return (
        key == 8 ||
        key == 9 ||
        key == 13 ||
        key == 46 ||
        key == 110 ||
        key == 190 ||
        (key >= 35 && key <= 40) ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105));
  });


  // Allow only three characters as input
  $('.limit-three-chars').keypress(function(key) {
    var value = $(this).val();
    if(value.length == 3) {
      return false;
    }
  });

  // Allow only two characters as input
  $('.limit-two-chars').keypress(function(key) {
    var value = $(this).val();
    if(value.length == 2) {
      return false;
    }
  });

  // Disable a link using disabled class
  $('a.disabled').click(function(e){
      e.preventDefault();
  });

  // Disable first Email field in Registration for Invited Participants if there is a value
  if ($.trim($('#webform-client-form-911344 #edit-submitted-email').val()).length != '') {
    $('#webform-client-form-911344 #edit-submitted-email').attr('disabled', 'disabled');
  }

  // jQuery Ajax call for clicking task checkboxes in Footsteps page
  $('.task-checkboxes').change(function(){
    var checked = this.checked ? 'Yes' : 'No';

    var stepdate = $(this).data('date');
    var taskid = $(this).data('taskid');

    //console.log(stepdate);
    //console.log(taskid);

    // Disable the checkbox
    $(this).attr('disabled', true);

    $.ajax({

      url: '/update-step',
      type: 'post',
      data: {'date': stepdate, 'taskid': taskid, 'checked': checked},
      success: function(data, status) {
        console.log(data);
        $('.task-checkboxes').attr('disabled', false);
        $('#block-wero-goal-wero-goal-nga-tapuwae-block .progress-outer .progress-inner').css('width', data + '%');
        $('#block-wero-goal-wero-goal-nga-tapuwae-block .progress-outer .progress-inner').text(data + '%');

        if(data == 100) {
          //$('#congratsModal').modal('show');
        }

      },
      error: function(xhr, desc, err) {
        $('.task-checkboxes').attr('disabled', false);
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

  });

  // jQuery Ajax call for updating Notification Status in Notifications page
  $('.accordion-notifications .notification-title').click(function(){

    var nid = $(this).data('nid');
    var currentlink = '#accordion-' + nid + ' a';
    var notificationsCount = $('.notifications-count').text();

    $.ajax({

      url: '/olaora-notifications-update',
      type: 'post',
      data: {'nid': nid},
      success: function(data, status) {
        console.log(data);

        if(data == 'updated') {
          $(currentlink).removeClass('notification-read-0');
          $(currentlink).addClass('notification-read-1');
          $('.notifications-count').text(notificationsCount - 1);
        }
      },
      error: function(xhr, desc, err) {
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

  });


  // jQuery Ajax call for unfriend in My Friend page
  $('.remove-friend-from-list').click(function(e){

    var friend_id = $(this).data('friendid');
    var currentlink = '#friend-thumbnail-container-' + friend_id;
    //console.log(currentlink);

    $.ajax({

      url: '/wf-unfriend-participant',
      type: 'post',
      data: {'friend_id': friend_id},
      success: function(data, status) {
          console.log(data);

          if(data == 'updated') {
              $(currentlink).css('display', 'none');
          }
      },
      error: function(xhr, desc, err) {
          console.log(xhr);
          console.log("Details: " + desc + "\nError:" + err);
      }

    });

      e.preventDefault();

  });

  // jQuery Ajax call for removing friend from a group
  $('.remove-friend-from-group').click(function(e){

    var friend_id = $(this).data('friendid');
    var group_id = $(this).data('groupid');
    var currentlink = '#friend-thumbnail-container-' + friend_id;
    console.log(currentlink);

    $.ajax({

      url: '/wf-remove-friend-from-group',
      type: 'post',
      data: {'friend_id': friend_id, 'group_id': group_id},
      success: function(data, status) {
        console.log(data);

        if(data == 'updated') {
          $(currentlink).css('display', 'none');
        }
      },
      error: function(xhr, desc, err) {
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

    e.preventDefault();

  });


  // jQuery Ajax call to update Journey Graph Data
  $('.journey-graph-month-arrows .btn').click(function(e){

    var startdate = $(this).attr('data-startdate');
    var enddate = $(this).attr('data-enddate');

    $.ajax({

      url: '/mj-get-journey-graph-data',
      type: 'post',
      data: {'startdate': startdate, 'enddate': enddate},
      success: function(data, status) {

        // Refresh Chart
        myChart.config.data.labels = data.graph_data.months;
        myChart.config.data.datasets[0].data = data.graph_data.goal_data;
        myChart.config.data.datasets[1].data = data.graph_data.task_data;
        myChart.config.data.datasets[2].data = data.graph_data.reward_data;
        myChart.update();

        // Change values in page
        $('.journey-graph-month-arrows .previous-month-arrow').attr('data-startdate', data.previous_start_date);
        $('.journey-graph-month-arrows .previous-month-arrow').attr('data-enddate', data.previous_end_date);
        $('.journey-graph-month-arrows .next-month-arrow').attr('data-startdate', data.next_start_date);
        $('.journey-graph-month-arrows .next-month-arrow').attr('data-enddate', data.next_end_date);

      },
      error: function(xhr, desc, err) {
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

    e.preventDefault();

  });


  // jQuery Ajax call to update Steps Graph Data
  $('.graph-date-range .btn').click(function(e){

    var searchdate = $(this).attr('data-searchdate');

    $.ajax({

      url: '/mt-get-tracker-graph-data',
      type: 'post',
      data: {'searchdate': searchdate},
      success: function(data, status) {

        // Refresh Chart
        myChart.config.data.datasets[0].data = data.steps;
        myChart.update();

        // Change values in page
        $('.graph-date-range .start-date').text(data.week_start_date);
        $('.graph-date-range .end-date').text(data.week_end_date);

        $('.graph-date-range .previous-wk-monday').attr('data-searchdate', data.previous_week_monday);
        $('.graph-date-range .next-wk-monday').attr('data-searchdate', data.next_week_monday);

      },
      error: function(xhr, desc, err) {
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

    e.preventDefault();

  });


  // jQuery Ajax call to update Water Graph Data
  $('.water-graph-date-range .btn').click(function(e){

    var searchdate = $(this).attr('data-searchdate');
    //console.log(searchdate);

    $.ajax({

      url: '/mt-get-water-tracker-graph-data',
      type: 'post',
      data: {'searchdate': searchdate},
      success: function(data, status) {
        //console.log(data);
        //console.log(myChart);
        //console.log(data);

        // Refresh Chart
        myChart.config.data.datasets[0].data = data.water;
        myChart.update();

        // Change values in page
        $('.water-graph-date-range .start-date').text(data.week_start_date);
        $('.water-graph-date-range .end-date').text(data.week_end_date);

        $('.water-graph-date-range .previous-wk-monday').attr('data-searchdate', data.previous_week_monday);
        $('.water-graph-date-range .next-wk-monday').attr('data-searchdate', data.next_week_monday);

      },
      error: function(xhr, desc, err) {
        console.log(xhr);
        console.log("Details: " + desc + "\nError:" + err);
      }

    });

    e.preventDefault();

  });

  // Show Showcase Modal when it is in the page
  if ($('#showcaseModal').length > 0) {
    $('#showcaseModal').modal('toggle');
  }



  // Add css to style Cancel button
  $('.form-actions #edit-cancel').addClass('btn btn-primary');


  $('#edit-field-m-wero-nga-tapuwae')
  $('#field-m-wero-nga-tapuwae-values').children().last().addClass('hidden');


  // Change plus and minus icon in FAQs page
  function toggleIcon(e) {
    $(e.target)
        .prev('.panel-heading')
        .find(".more-less")
        .toggleClass('glyphicon-plus glyphicon-minus');
  }

  $('.panel-group').on('hidden.bs.collapse', toggleIcon);
  $('.panel-group').on('shown.bs.collapse', toggleIcon);


});

